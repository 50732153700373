<template>
	<v-container fluid>
		<v-row v-if="year">
			<v-breadcrumbs :items="breadcrumbs">
				<template v-slot:divider>
					<v-icon>mdi-arrow-right</v-icon>
				</template>
			</v-breadcrumbs>
		</v-row>
		<v-row>
			<v-col v-if="memories.length < 1" cols="12" lg="6" xl="4" class="mx-auto">
				<r-no-memory/>
			</v-col>

			<v-col v-else cols="12" sm="6" md="4" lg="3" v-for="(memory, i) in memories">
				<r-memory-tile
					:thumbnail="memory.thumbnail"
					:title="memory.title"
					:to="{name: 'ShowMemory', params: {id: memory.id}}"
					:icon="memory.icon"
				></r-memory-tile>
			</v-col>

		</v-row>
	</v-container>
</template>

<script>

import api from "@/services/api";
import _sample from 'lodash/sample'
import {sync} from "vuex-pathify";

export default {
	name: 'Topic',
	metaInfo() {
		return {
			title: this.topic || 'Category',
		}
	},
	props: ['topic', 'year'],
	computed: {
		title: sync('app/title'),
		breadcrumbs() {
			if (this.year) {
				return [
					{
						text: this.year,
						disabled: false,
						to: {name: 'Year', params: {year: this.year}},
					},
					{
						text: this.topic,
						disabled: false,
						to: {name: 'Topic', params: {topic: this.topic.slug}},
					},
				]
			} else {
				return []
			}
		}
	},
	watch: {
		topic() {
			this.getTopic()
			this.title = this.topic
		}
	},
	beforeMount() {
		this.memories = []
	},
	mounted() {
		this.getTopic()
		this.title = this.topic
	},
	data: () => ({
		memories: [],
	}),
	methods: {
		getTopic() {
			this.$api.topics.show(this.topic, this.year)
				.then(response => {
					this.memories = response.data
				})
				.catch(error => console.log(error))
		},
	}
}
</script>
